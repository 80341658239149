import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url('/img/about_img_main.jpg')`,
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
            backgroundColor: "#f40",
            color: "white",
            padding: "0.3rem",
          }}
        >
          {title}
        </h1>
      </div>
      <div className="container container__about">
        <section className="section">
          <div className="content">
            <PageContent className="content" content={content} />
            <table class="about__table">
              <tr>
                <td>称 号</td>
                <td>株式会社init</td>
              </tr>
              <tr>
                <td>代 表 者</td>
                <td>加納 聖也</td>
              </tr>
              <tr>
                <td>所 在 地</td>
                <td>〒591-8031 大阪府堺市北区百舌鳥梅北町4-133-3-302</td>
              </tr>
              <tr>
                <td>E-mail</td>
                <td>info@init-jp.com</td>
              </tr>
              <tr>
                <td>TEL</td>
                <td>050-3699-6820</td>
              </tr>
              <tr>
                <td>FAX</td>
                <td>050-3488-6276</td>
              </tr>
            </table>
          </div>
        </section>
      </div>
    </>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
